$(document).ready(function () {

    var $dateFrom = $(".dateFrom");
    var $dateTo = $(".dateTo");
    var $dateOnly = $(".dateOnly");
    var $datepickerSettings = {
        icons: {
            time: "fa fa-clock-o",
            date: "fa fa-calendar",
            up: "fa fa-arrow-up",
            down: "fa fa-arrow-down",
            previous: "fa fa-arrow-left",
            next: "fa fa-arrow-right",
            today: 'fa fa-screenshot',
            clear: 'fa fa-trash',
            close: 'fa fa-remove'
        },
        format: 'YYYY-MM-DD',
        useStrict: true,
        showClear: true,
        toolbarPlacement: 'top',
        ignoreReadonly: true,
    };
    var $dateToSettings = $datepickerSettings;

    $dateToSettings['useCurrent'] = false;
    $dateOnly.datetimepicker($datepickerSettings);
    $dateFrom.datetimepicker($datepickerSettings);
    $dateTo.datetimepicker($dateToSettings);
    $dateFrom.on("dp.change", function (e) {
        $dateTo.data("DateTimePicker").minDate(e.date);
    });
    $dateTo.on("dp.change", function (e) {
        $dateFrom.data("DateTimePicker").maxDate(e.date);
    });


});
